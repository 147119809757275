var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-layout",
    { attrs: { id: "gx-layout" } },
    [
      _c(
        "a-layout-sider",
        {
          staticClass: "gx-app-sidebar gx-layout-sider-dark",
          attrs: { trigger: null, collapsible: "" },
          model: {
            value: _vm.collapsed,
            callback: function ($$v) {
              _vm.collapsed = $$v
            },
            expression: "collapsed",
          },
        },
        [
          _c(
            "div",
            { staticClass: "gx-layout-sider-header" },
            [
              _c("div", { staticClass: "gx-linebar" }, [
                _c("i", {
                  staticClass: "gx-icon-btn icon gx-text-white",
                  class: {
                    "icon-menu-unfold": _vm.collapsed,
                    "icon-menu-fold": !_vm.collapsed,
                  },
                  attrs: { type: _vm.collapsed ? "menu-unfold" : "menu-fold" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleSidebar()
                    },
                  },
                }),
              ]),
              _c(
                "router-link",
                { staticClass: "gx-site-logo", attrs: { to: "/" } },
                [
                  _c("img", {
                    staticStyle: { "max-height": "50px" },
                    attrs: {
                      alt: "SubsApp",
                      src: require("@/assets/regular-logo.png"),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm.user.select_role === "club_admin" ||
          _vm.user.select_role === "coach"
            ? _c("menu-club")
            : _vm._e(),
          _vm.user.select_role === "member" ? _c("menu-user") : _vm._e(),
          _vm.user.select_role === "admin" ? _c("menu-admin") : _vm._e(),
        ],
        1
      ),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-header",
            [
              _c(
                "div",
                {
                  staticClass: "gx-d-lg-none gx-d-block gx-linebar gx-mr-3",
                  on: {
                    click: function ($event) {
                      return _vm.toggleSidebar()
                    },
                  },
                },
                [_c("i", { staticClass: "gx-icon-btn icon icon-menu" })]
              ),
              _c(
                "router-link",
                {
                  staticClass: "gx-d-block gx-d-lg-none gx-pointer",
                  attrs: { to: "/" },
                },
                [
                  _c("img", {
                    staticStyle: { "max-height": "50px" },
                    attrs: {
                      alt: "LeadSurge",
                      src: require("@/assets/regular-logo.png"),
                    },
                  }),
                ]
              ),
              _vm.user && _vm.user.user
                ? _c(
                    "ul",
                    { staticClass: "gx-header-notifications gx-ml-auto" },
                    [
                      _c(
                        "li",
                        { staticClass: "gx-user-nav" },
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { "align-items": "center" },
                              attrs: { type: "flex" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ant-avatar gx-avatar gx-pointer ant-avatar-circle ant-avatar-image",
                                },
                                [
                                  _vm.userProfileAvatar
                                    ? _c("img", {
                                        attrs: {
                                          alt: "",
                                          src: _vm.userProfileAvatar,
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          alt: "",
                                          src: require("@/assets/user.png"),
                                        },
                                      }),
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { to: "/my-profile", tag: "div" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "gx-user-nav--name" },
                                    [
                                      _vm._v(
                                        "\n                Hi " +
                                          _vm._s(_vm.user.user.first_name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm.user.user_type.length > 1
                                    ? _c(
                                        "a-dropdown",
                                        {
                                          staticClass: "switch-as",
                                          attrs: { trigger: ["click"] },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ant-dropdown-link",
                                              on: {
                                                click: (e) =>
                                                  e.preventDefault(),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(this.UserName) +
                                                  " "
                                              ),
                                              _c("a-icon", {
                                                attrs: { type: "setting" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-menu",
                                            {
                                              attrs: { slot: "overlay" },
                                              slot: "overlay",
                                            },
                                            [
                                              _vm.user.user_type.indexOf(
                                                "admin"
                                              ) !== -1
                                                ? _c(
                                                    "a-menu-item",
                                                    { key: "1" },
                                                    [
                                                      _c("a-menu-divider"),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "switch-as-users",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.setRole(
                                                                "admin"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Use as Admin")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.user.user_type.indexOf(
                                                "club_admin"
                                              ) !== -1
                                                ? _c(
                                                    "a-menu-item",
                                                    { key: "2" },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "switch-as-users",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.setRole(
                                                                "club_admin"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              this.ClubAdmin
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.user.user_type.indexOf(
                                                "coach"
                                              ) !== -1
                                                ? _c(
                                                    "a-menu-item",
                                                    { key: "3" },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "switch-as-users",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.setRole(
                                                                "coach"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(this.Coach)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.user.user_type.indexOf(
                                                "member"
                                              ) !== -1
                                                ? _c(
                                                    "a-menu-item",
                                                    { key: "4" },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "switch-as-users",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.setRole(
                                                                "member"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(this.Member)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-layout-content",
            { staticClass: "gx-layout-content" },
            [
              _vm.$route.path !== "/" &&
              _vm.$route.path !== "/admin" &&
              _vm.$route.path !== "/dashboard"
                ? _c(
                    "a-button",
                    {
                      staticClass: "back-button gx-mb-0",
                      attrs: { block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "left" } }),
                      _vm._v(
                        "Go Back " + _vm._s(_vm.$router.path) + "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mobile-menu" },
        [
          _c(
            "a-drawer",
            {
              attrs: {
                placement: "left",
                "wrap-class-name": "mobile-menu",
                visible: _vm.collapsed,
              },
              on: {
                close: function ($event) {
                  return _vm.hideSidebar()
                },
              },
            },
            [
              _vm.user.select_role === "club_admin" ||
              _vm.user.select_role === "coach"
                ? _c("menu-club")
                : _vm._e(),
              _vm.user.select_role === "member" ? _c("menu-user") : _vm._e(),
              _vm.user.select_role === "admin" ? _c("menu-admin") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }