import { render, staticRenderFns } from "./menu-user.vue?vue&type=template&id=481a3e62&scoped=true"
import script from "./menu-user.vue?vue&type=script&lang=js"
export * from "./menu-user.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481a3e62",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Subsapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('481a3e62')) {
      api.createRecord('481a3e62', component.options)
    } else {
      api.reload('481a3e62', component.options)
    }
    module.hot.accept("./menu-user.vue?vue&type=template&id=481a3e62&scoped=true", function () {
      api.rerender('481a3e62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/menus/menu-user.vue"
export default component.exports